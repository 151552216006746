<div class="fixed left-0 top-0 z-30 w-full shadow-sm print:hidden" [ngClass]="{ 'dark text-white-dark': store.semidark && store.menu === 'horizontal'}">
  <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
    <div class="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
      <a routerLink="/dashboard/sales" class="main-logo flex shrink-0 items-center">
        <img class="ml-[5px] h-auto w-12 flex-none" src="/assets/images/logo.svg" alt="" />
        <img class="ml-[5px] h-10" src="/assets/images/logo-text.svg" alt="" />
      </a>

      <a
        href="javascript:;"
        class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden ltr:ml-2 rtl:mr-2"
        (click)="storeData.dispatch({type: 'toggleSidebar'})"
      >
        <icon-menu class="h-5 w-5" />
      </a>
    </div>
    <div class="flex items-center space-x-1.5 dark:text-[#d0d2d6] sm:flex-1 lg:space-x-2 ltr:ml-auto ltr:sm:ml-0 rtl:mr-auto rtl:space-x-reverse sm:rtl:mr-0">
      <div class="sm:ltr:mr-auto sm:rtl:ml-auto"></div>

      <div class="shrink-0">
        <div class="hidden md:block ltr:mr-2 rtl:ml-2">
          <ul class="flex items-center space-x-2 dark:text-[#d0d2d6] rtl:space-x-reverse">
            <li *appHasPackageRight="['calendarAccounting']">
              <button (click)="navigateToCalendar()" class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                <icon-calendar />
              </button>
            </li>
            <li [ngxTippy]="('higher_tier' | translate)" *appHasPackageRight="['calendarAccounting',true]">
              <button class="block cursor-not-allowed rounded-full bg-white-light/40 p-2 dark:bg-dark/40">
                <icon-calendar class="text-gray-500" />
              </button>
            </li>

            <li *appHasPackageRight="['calendarAccounting']">
              <ng-select [items]="cars" bindLabel="name" bindValue="id" [clearable]="false" [searchable]="false" [(ngModel)]="pickerType"></ng-select>
            </li>

            <li *appHasPackageRight="['calendarAccounting']">
              <div class="hidden md:block">
                <app-datepicker [store]="store" [pickerType]="pickerType"></app-datepicker>
              </div>
            </li>
            <li>
              <app-etourist-checker></app-etourist-checker>
            </li>
          </ul>
        </div>
      </div>

      <div class="dropdown shrink-0" *ngIf="user">
        <div hlMenu class="!block">
          <button type="button" hlMenuButton class="group relative block">
            <img class="h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100" [src]="user.imageUrl ?? '/assets/images/auth/user-icon.png'" alt="" />
          </button>

          <ul *hlMenuItems @toggleAnimation class="top-11 w-[230px] !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90 ltr:right-0 rtl:left-0">
            <li>
              <div class="flex items-center px-4 py-4">
                <div class="flex-none">
                  <img class="h-10 w-10 rounded-md object-cover" [src]="user.imageUrl" alt="" />
                </div>

                <div class="truncate ltr:pl-4 rtl:pr-4">
                  <h4 class="text-base">
                    <a class="text-black hover:text-primary dark:text-dark-light/60 dark:hover:text-white" routerLink="/dashboard/user-profile"> {{user.name}} {{user.surname}} </a>
                  </h4>
                  <a class="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white" href="javascript:;">{{user.username}}</a>
                </div>
              </div>
              <hr />
            </li>

            <li>
              <div *hlMenuItem="let menuItem" class="flex items-center space-x-2 px-4 dark:text-[#d0d2d6] dark:hover:text-white ltr:mr-2 rtl:ml-2">{{"choose_a_theme"|translate}}:</div>
              <div class="flex items-center space-x-2 truncate dark:text-[#d0d2d6] ltr:mr-2 ltr:pl-4 rtl:ml-2 rtl:pr-4" style="padding-bottom: 4px">
                <button
                  type="button"
                  class="flex items-center rounded-full p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                  [ngClass]="{'dark:bg-sky-400 bg-sky-400 text text-white': store.theme === 'light', 'bg-white-light/40': store.theme !== 'light'}"
                  (click)="storeData.dispatch({type: 'toggleTheme', payload:'light'})"
                >
                  <icon-sun />
                </button>
                <button
                  type="button"
                  class="flex items-center rounded-full p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                  [ngClass]="{'dark:bg-sky-400 bg-sky-400 text text-white': store.theme === 'dark', 'dark:bg-dark/40': store.theme !== 'dark'}"
                  (click)="storeData.dispatch({type: 'toggleTheme', payload:'dark'})"
                >
                  <icon-moon />
                </button>
                <button
                  type="button"
                  class="flex items-center rounded-full p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                  [ngClass]="{'dark:bg-sky-400 bg-sky-400 text text-white': store.theme === 'system', 'dark:bg-dark/40': store.theme !== 'system'}"
                  (click)="storeData.dispatch({type: 'toggleTheme', payload:'system'})"
                >
                  <icon-laptop />
                </button>
              </div>

              <hr />
            </li>

            <li>
              <div *hlMenuItem="let menuItem" class="flex items-center space-x-2 px-4 dark:text-[#d0d2d6] dark:hover:text-white ltr:mr-2 rtl:ml-2">{{"choose_a_language"|translate}}:</div>
              <div class="flex items-center space-x-2 truncate pb-2 dark:text-[#d0d2d6] ltr:mr-2 ltr:pl-4 rtl:ml-2 rtl:pr-4">
                <ng-container *ngFor="let item of (languageList$ | async)">
                  <button
                    type="button"
                    class="block rounded-full p-2 hover:bg-white-light/90 hover:text-primary"
                    [ngClass]="{
											'bg-sky-400 dark:bg-sky-400': translate.currentLang === item.code,
											'bg-white-light/40 dark:bg-dark/40': translate.currentLang !== item.code
										}"
                    (click)="changeLanguage(item)"
                  >
                    <img [src]="'/assets/images/flags/' + item.code.toUpperCase() + '.svg'" alt="flag" class="h-5 w-5 rounded-full object-cover" />
                  </button>
                </ng-container>
              </div>
              <hr />
            </li>

            <li>
              <a routerLink="/dashboard/settings" *hlMenuItem="let menuItem" class="dark:hover:text-white">
                <icon-settings class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                {{ 'settings_label' | translate}}
              </a>
            </li>
            <li>
              <a routerLink="/auth/lock" *hlMenuItem="let menuItem" class="dark:hover:text-white">
                <icon-lock-dots class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                {{"screen_lock" | translate}}
              </a>
            </li>
            <li class="border-t border-white-light dark:border-white-light/10">
              <a routerLink="/auth/login" *hlMenuItem="let menuItem" class="!py-3 text-danger">
                <icon-logout class="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />

                {{'sign_out' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
