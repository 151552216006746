import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Destination } from "../models/destination";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { ModalService } from 'src/app/shared/services/modal.service';
import { TableService } from "src/app/shared/services/table.service";
import { DestinationsService } from "../services/destinations.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { EditDestinationComponent } from "../edit-destination/edit-destination.component";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";


@Component({
  templateUrl: "./destinations.table.component.html",
  animations: [toggleAnimation],
})
export class DestinationsTableComponent extends BaseTableComponent<Destination> implements OnInit {
  public destinations: Destination[] = [];
  foundDestination: Destination[] = [];
  filteredDestination: Destination[] = [];
  selectedDestinationIds: number[] = [];
  editDestinations!: Destination;
  destination!: Observable<Destination[]>;
  mergeMode = false;
  description!: string;
  term: any;
  destinationId = 0;
  public form!: FormGroup;
  selectedDestination: Destination[] = [];
  isLoading = false;
  editMode = false;
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  @ViewChild(EditDestinationComponent) formComponent!: EditDestinationComponent;
  EntityPicklistType = EntityPicklistType;

  override ngOnInit(): void {
    super.ngOnInit();
    this.loadDestinations();
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
  constructor(
    private positioningService: PositioningService,
    private destinationsService: DestinationsService,
    public translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    public utilService: UtilService,
    public modalService: ModalService
  ) {
    super(store, tableService, `destinations/paginated`);
  }
  addDestination() {
    this.modalService.openDestinationAddModal().then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.loadDestinations();
        showMessage(PopupType.Success, this.translate.instant("destinations_data.added_destination_successfully"));
      }
    })
  }
  editDestination(destination: Destination) {
    this.modalService.openDestinationEditModal(destination).then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.loadDestinations();
        showMessage(PopupType.Success, this.translate.instant("destinations_data.updated_destination_successfully"));
      }
    })
  }
  loadDestinations() {
    this.filteredDestination = [];
    this.foundDestination = [];
    this.selectedDestinationIds = [];
    this.destination = this.destinationsService.getDestinations().pipe(map((company: Destination[]) => company.filter((organizer) => organizer.id !== this.destinationId)));
  }

  addMerge(id: number, description: string) {
    this.destinationId = id;
    this.description = description;
    this.mergeMode = true;
    this.modal.open();
    this.foundDestination = [];
    this.filteredDestination = [];
    this.term = "";
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("destination_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "description", title: this.translate.instant("destination_info.description"), hide: false } as DatatableColumn,
      { field: "numberOfTravels", title: this.translate.instant("destination_info.number_of_travels"), hide: false, sort: false } as DatatableColumn,
      { field: "numberOfTickets", title: this.translate.instant("destination_info.number_of_tickets"), hide: false, sort: false } as DatatableColumn,
      { field: "destinationBrutoSum", title: this.translate.instant("destination_info.destination_bruto_sum"), hide: false, sort: false } as DatatableColumn,
      { field: "destinationNetoSum", title: this.translate.instant("destination_info.destination_neto_sum"), hide: false, sort: false } as DatatableColumn,
      { field: "passangersCount", title: this.translate.instant("destination_info.passangers_count"), hide: false, sort: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("destination_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("destination_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfTravels", title: this.translate.instant("destination_export.numberOfTravels"), hide: false } as DatatableColumn,
    { field: "numberOfTickets", title: this.translate.instant("destination_export.numberOfTickets"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("destination_export.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("destination_export.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "passangersCount", title: this.translate.instant("destination_export.passangersCount"), hide: false } as DatatableColumn,
  ];

  editDestinationData(data: Destination): void {
    this.isLoading = true;
    if (this.editMode) {
      const updateSubscription = this.destinationsService.updateDestination(this.destinationId, data).subscribe({
        next: () => {
          updateSubscription.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("destinations_data.updated_destination_successfully"));
          this.updatePaginationProps.next(this.getPaginationParams());
          this.isLoading = false;
        },
        error: () => {
          updateSubscription.unsubscribe();
          showMessage(PopupType.Warning, this.translate.instant("destinations_data.error_updating_destination"));
          this.isLoading = false;
        },
      });
    } else {
      const createSubscription = this.destinationsService.addDestination(data).subscribe({
        next: () => {
          createSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
          showMessage(PopupType.Success, this.translate.instant("destinations_data.added_destination_successfully"));
          this.isLoading = false;
        },
        error: () => {
          createSubscription.unsubscribe();
          showMessage(PopupType.Warning, this.translate.instant("destinations_data.error_adding_destination"));
          this.isLoading = false;
        },
      });
    }
  }

  mergeSelectedDestinationId(selectedDestinationIds: number[]) {
    if (selectedDestinationIds.length > 0 && this.destinationId) {
      this.isLoading = true;
      const mergeSubscription = this.destinationsService.mergeDestination(this.destinationId, selectedDestinationIds).subscribe({
        next: () => {
          this.isLoading = false;
          this.updatePaginationProps.next(this.getPaginationParams());
          this.loadDestinations();
          showMessage(PopupType.Success, this.translate.instant("destinations_data.destinations_merged_successfully"));
          mergeSubscription.unsubscribe();
          this.modal.close();
        },
        error: () => {
          this.isLoading = false;
          showMessage(PopupType.Warning, this.translate.instant("destinations_data.error_merging_destinations"));
        },
      })
    }
  }
}
