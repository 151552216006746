import { createReducer, on } from "@ngrx/store";
import { setLanguage, loadLanguages } from "./language.actions";

export interface LanguageState {
  locale: string;
  languageList: { code: string; name: string }[];
}

export const initialLanguageState: LanguageState = {
  locale: "en",
  languageList: [
    { code: "en", name: "English" },
    { code: "sr", name: "Serbian" }
  ]
};

export const languageReducer = createReducer(
  initialLanguageState,
  on(setLanguage, (state, { locale }) => {
    if (locale) {
      localStorage.setItem("i18n_locale", locale);
    }
    return {
      ...state,
      locale
    };
  }),
  on(loadLanguages, (state) => ({
    ...state
  }))
);
